<template>
    <div class="">
        <div class="flex justify-between items-center mb-6">
            <div>
                <h1 class="text-4xl">Worksheets</h1>
            </div>

            <div class="flex">
                <div>
                    <input
                        class="p-2 border w-80 rounded"
                        v-model="filterWorkStation"
                        @keyup="search()"
                        type="search"
                        placeholder="Search by worksheet id"
                    />
                </div>
                <button @click="rqFilter()" class="mx-2">
                    <span>
                        <i
                            :class="{ 'text-blue-600': onFilter }"
                            class="bx bx-filter-alt text-2xl"
                        ></i>
                    </span>
                </button>
            </div>
        </div>

        <div class="flex flex-col">
            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div
                    class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
                >
                    <div
                        class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
                    >
                        <div
                            v-if="onFilter"
                            class="flex grid-flow-col gap-4 bg-gray-50 p-3"
                        >
                            <div class="flex flex-col w-80">
                                <label class="text-gray-600" for="branch"
                                    >Filter by branch
                                </label>
                                <select
                                    class="p-2 border rounded"
                                    name="branch"
                                    @change="loadData(1, filter)"
                                    v-model="filterBranch"
                                >
                                    <option value="" disabled selected>
                                        Filter by branch
                                    </option>
                                    <option value="">All</option>
                                    <option
                                        v-for="(branch, i) in branches"
                                        :key="i"
                                        :value="branch.id"
                                    >
                                        {{ branch.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="flex flex-col w-80">
                                <label class="text-gray-600" for="branch"
                                    >Filter by staff
                                </label>
                                <select
                                    class="p-2 border rounded"
                                    name="branch"
                                    @change="loadData(1, filter)"
                                    v-model="filterStaff"
                                >
                                    <option value="" disabled selected>
                                        Filter by staff
                                    </option>
                                    <option value="">All</option>
                                    <option
                                        v-for="(staff, i) in staffs"
                                        :key="i"
                                        :value="staff.id"
                                    >
                                        {{ staff.name }}
                                    </option>
                                </select>
                            </div>

                            <div class="flex flex-col w-80">
                                <label class="text-gray-600" for="branch"
                                    >Filter by status
                                </label>
                                <select
                                    class="p-2 border rounded"
                                    name="collection_status"
                                    @change="loadData(1, filter)"
                                    v-model="collection_status"
                                >
                                    <option value="" disabled selected>
                                        Filter by Status
                                    </option>
                                    <option value="">All</option>
                                    <option value="PENDING">Pending</option>
                                    <option value="COLLECTED">Collected</option>
                                </select>
                            </div>

                            <div class="flex flex-col w-80">
                                <label class="text-gray-600" for="branch"
                                    >Filter by date
                                </label>
                                <input
                                    class="p-2 border rounded text-xs"
                                    type="date"
                                    name="date"
                                    @change="loadData(1, filterDate)"
                                    v-model="filterDate"
                                />
                            </div>
                        </div>

                        <table class="min-w-full divide-y divide-gray-200">
                            <thead class="bg-gray-50">
                                <tr>
                                    <th
                                        scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        ID
                                    </th>
                                    <th
                                        scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Staff
                                    </th>
                                    <th
                                        scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Date / Created
                                    </th>
                                    <th
                                        scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Total Deposits
                                    </th>
                                    <th
                                        scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Total Sales
                                    </th>
                                    <th
                                        scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Cash In Hand
                                    </th>

                                    <th
                                        scope="col"
                                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                        Status
                                    </th>

                                    <th scope="col" class="relative px-6 py-3">
                                        <span class="sr-only">Print</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                                <tr
                                    v-for="(worksheet, i) in worksheets.data"
                                    :key="i"
                                >
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="flex items-center">
                                            <div class="">
                                                <div
                                                    class="text-sm font-medium text-gray-900"
                                                >
                                                    {{ worksheet.ws_id }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="flex items-center">
                                            <div class="">
                                                <div
                                                    class="text-sm font-medium text-gray-900"
                                                >
                                                    {{ worksheet.staff }}
                                                </div>
                                                <div
                                                    class="text-sm text-gray-500"
                                                >
                                                    {{ worksheet.branch }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="flex items-center">
                                            <div class="">
                                                <div
                                                    class="text-sm font-medium text-gray-900"
                                                >
                                                    {{ worksheet.date }}
                                                </div>
                                                <div
                                                    class="text-sm text-gray-500"
                                                >
                                                    {{ worksheet.created_at }}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="text-sm text-gray-900">
                                            GMD
                                            <b>{{
                                                worksheet.total_deposits
                                            }}</b>
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="text-sm text-gray-900">
                                            GMD
                                            <b>{{ worksheet.total_sales }}</b>
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <div class="text-sm text-gray-900">
                                            GMD
                                            <b>{{ worksheet.cash_in_hand }}</b>
                                        </div>
                                    </td>

                                    <td class="px-6 py-4 whitespace-nowrap">
                                        <span
                                            v-if="worksheet.collection_status"
                                            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                                        >
                                            COLLECTED
                                        </span>

                                        <span
                                            v-else
                                            class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800"
                                        >
                                            PENDING
                                        </span>
                                    </td>

                                    <td
                                        class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                                    >
                                        <button
                                            v-if="
                                                checkPermissionExist(
                                                    user.data,
                                                    'revise-worksheet'
                                                )
                                            "
                                            @click="onRevise(worksheet)"
                                            class="text-indigo-600 hover:text-indigo-900 mx-4"
                                        >
                                            Revise
                                        </button>
                                        <button
                                            class="text-indigo-600 hover:text-indigo-900"
                                            @click="print(worksheet)"
                                        >
                                            Print
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="border-t" v-if="worksheets">
                            <pagination
                                :data="worksheets"
                                :midSize="2"
                                @clicked="loadData"
                            >
                            </pagination>
                        </div>
                    </div>
                </div>
                <!-- <worksheet-print :data="selectedPrint" /> -->

                <modal width="50%" height="auto" name="revise">
                    <Revise :selected="selected" @complete="onComplete()" />
                </modal>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import JSPM from '../../../public/JSPrintManager';
import Pagination from '../../components/Pagination';
import FormatPrice from '@/mixins/FormatPrice';
import Revise from './components/Revise';
import { mapGetters } from 'vuex';
import checkPermissionExist from '../../mixins/checkPermissionExist';

export default {
    middleware: 'auth',
    layout: 'default',
    components: {
        Revise,
        Pagination,
    },

    mixins: [checkPermissionExist, FormatPrice],
    computed: mapGetters({
        user: 'auth/user',
    }),

    data: () => ({
        worksheets: [],
        searching: false,
        print2default: true,
        selectedPrint: null,
        selected: null,

        filter: '',
        onFilter: false,

        filterDate: '',
        collection_status: '',
        filterBranch: '',
        filterStaff: '',

        filterWorkStation: '',
        workStations: [],
        branches: [],
        staffs: [],

        pritingWorksheet: null,
    }),

    created() {
        var _this = this;
        _this.loadData(1);
        if (!this.checkPermissionExist(this.user.data, 'worksheets-read')) {
            this.$router.go(-1);
            return false;
        }
    },

    mounted: function () {
        this.onInit();
    },

    methods: {
        onRevise(parm) {
            this.selected = parm;
            this.$modal.show('revise');
        },

        search() {
            var vm = this;
            this.searching = true;
            vm.loadData(1, vm.filter);
        },

        rqFilter() {
            this.onFilter = !this.onFilter;

            if (this.onFilter) {
                this.loadBranches();
                this.loadStaffs();
            }
        },

        async loadBranches() {
            // Submit the form

            try {
                const { data } = await axios.get(`/get/branches`);

                this.branches = data.data;

                return true;
            } catch (e) {
                this.isLoading = false;
                return false;
            }
        },

        async loadStaffs() {
            // Submit the form

            try {
                const { data } = await axios.get(`/staff/all`);

                this.staffs = data.data;

                return true;
            } catch (e) {
                this.isLoading = false;
                return false;
            }
        },

        print(worksheet) {
            if (this.selected_printer === '' && !this.print2default) {
                alert('You must select a printer');
                return;
            }

            var clietnPrintJob = new JSPM.ClientPrintJob();

            if (this.print2default) {
                clietnPrintJob.clientPrinter = new JSPM.DefaultPrinter();
            } else {
                clietnPrintJob.clientPrinter = new JSPM.InstalledPrinter(
                    this.selected_printer
                );
            }

            var dateCreated = moment(worksheet.created_at).format(
                'Do MMM, YYYY, h:mm:ss a'
            );

            //Set content to print...
            //Create ESP/POS commands for sample label
            var esc = '\x1B'; //ESC byte in hex notation
            var newLine = '\x0A'; //LF byte in hex notation
            var fullCut = esc + 'm';

            var commands = esc + '@'; //Initializes the printer (ESC @)
            commands += esc + '!' + '\x38'; //Emphasized + Double-height + Double-width mode selected (ESC ! (8 + 16 + 32)) 56 dec => 38 hex
            commands += 'WORKSHEET'; //text to print
            commands += newLine;
            commands += esc + '!' + '\x00'; //Character font A selected (ESC ! 0)

            commands += newLine + newLine;

            commands += `ID .:                ${worksheet.ws_id}`;
            commands += newLine;
            commands += `Date .:              ${worksheet.date}`;
            commands += newLine;
            commands += `Staff .:             ${worksheet.staff}`;
            commands += newLine;
            commands += `Branch .:            ${worksheet.branch}`;
            commands += newLine;
            commands += '--------------------------------------------';
            commands += newLine;
            commands += `TOTAL SALES .:       GMD ${this.formatPrice(
                worksheet.total_sales
            )}`;
            commands += newLine;
            commands += `TOTAL DEPOSITS .:    GMD ${this.formatPrice(
                worksheet.total_deposits / 100
            )}`;
            commands += newLine;
            commands += esc + '!' + '\x18'; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex
            commands += `CASH IN HAND .:      GMD ${this.formatPrice(
                worksheet.cash_in_hand
            )}`;
            commands += esc + '!' + '\x00'; //Emphasized + Double-height mode selected (ESC ! (16 + 8)) 24 dec => 18 hex
            commands += newLine;
            commands += '--------------------------------------------';

            commands += newLine + newLine;
            commands += newLine + newLine;
            commands += newLine;

            commands += '--------------------------------------------';
            commands += newLine;
            commands += `Signature (${worksheet.staff})`;
            commands += newLine;
            commands += `Created .:  ${dateCreated}`;
            commands += newLine + newLine;
            commands += newLine + newLine;

            commands += fullCut;

            clietnPrintJob.printerCommands = commands;

            //Send print job to printer!
            clietnPrintJob.sendToClient();
        },

        async loadData(pageNo, filter) {
            // Submit the form

            if (filter) {
                this.searching = true;
            } else {
                filter = '';
                this.searching = false;
            }

            try {
                const { data } = await axios.get(
                    `/worksheets?page=${pageNo}&filter=${this.filter}&ws_id=${this.filterWorkStation}&branche_id=${this.filterBranch}&staff_id=${this.filterStaff}&collection_status=${this.collection_status}&date=${this.filterDate}`
                );

                this.worksheets = data;
                this.searching = false;

                if (this.workStations.length == 0) {
                    this.workStations = data.data.map(m => m.ws_id);
                }

                return true;
            } catch (e) {
                this.searching = false;
                return false;
            }
        },

        onInit: function () {
            var _this = this;
            JSPM.JSPrintManager.auto_reconnect = true;
            JSPM.JSPrintManager.start();
            JSPM.JSPrintManager.WS.onStatusChanged = function () {
                _this.getPrinters().then(p => {
                    _this.printers = p;
                    _this.$nextTick();
                });
            };
        },

        getPrinters: function () {
            return new Promise((ok, err) => {
                let printers = [];
                if (
                    JSPM.JSPrintManager.websocket_status == JSPM.WSStatus.Open
                ) {
                    JSPM.JSPrintManager.getPrinters()
                        .then(function (myPrinters) {
                            printers = myPrinters;
                            ok(printers);
                        })
                        .catch(e => err(e));
                } else {
                    ok(printers);
                }
            });
        },

        onComplete() {
            this.$modal.hide('revise');
            this.loadData(1, this.filter);
        },
    },
};
</script>
